<template>
  <div class="record">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/simpleMatching/search' }">政策匹配</el-breadcrumb-item>
      <el-breadcrumb-item>匹配记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main">
      <div class="header">
        <div class="header_left" @click="() => $router.push('/simpleMatching/search')">
          <i class="el-icon-arrow-left"></i>
          返回
        </div>
        <div class="header_right">仅记录企业最近一次的匹配记录</div>
      </div>
      <div v-if="data.length > 0">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="content"
        >
          <div class="content_card">
            <div class="card_left">
              <div class="left_title">{{ item.entityName }}</div>
              <div class="left_time">
                <div class="time">{{ item.createTime }}</div>
                <!-- <div class="time_icon">简易版</div> -->
              </div>
            </div>
            <div class="card_right">
              <div class="right_btn" @click="resetMatching(item.entityName)">
                <i class="el-icon-refresh-right"></i>
                重新匹配
              </div>
              <div class="right_project" @click="getDetails(item)">
                匹配到项目
                <span class="project_num">{{ item.projectNum }}</span>个
              </div>
              <!-- <div class="right_icon">
                <i
                  style="cursor: pointer;"
                  :class="[item.show ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"
                  @click="() => item.show = !item.show"
                ></i>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <el-empty description="暂无内容"></el-empty>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      @close="closeDialog"
      :width="dialogWidth"
      destroy-on-close
      title="匹配详情"
    >
      <div v-if="details.length > 0" class="content_box">
        <div class="box_title">扶持项目({{ projectNum }})</div>
        <div class="box_table">
          <table border="1" width="1010">
            <tr>
              <th>项目简称</th>
              <th>发文部门</th>
            </tr>
            <tr
              v-for="(child, index) in details"
              :key="index"
            >
              <td @click="goToDetail(child)" style="cursor: pointer;">{{ child.subtitle }}</td>
              <td>{{ child.department }}</td>
            </tr>
          </table>
        </div>
        <div class="box_foot" v-if="showPage">
          <el-pagination
            layout="prev, pager, next"
            :total="projectNum"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div v-else>
        <el-empty description="没有项目"></el-empty>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showDialog"
      @close="closeDia"
      :width="dialogWid"
      destroy-on-close
      title=""
    >
      <component
        :is="componentName"
      ></component>
    </el-dialog>
  </div>
</template>

<script>
import { getMatchRecord, getMatchRecordResult } from '@/api/simple_matching/index';
import { getMemberId } from '@/assets/public/utils/token';
import { mapState, mapMutations } from 'vuex';

export default {
  data () {
    return {
      dialogVisible: false,
      dialogWidth: '1100px',
      show: false,
      projectNum: 32,
      details: [],
      detailParams: {
        recordId: null,
        pageNo: 1,
        pageSize: 10
      },
      data: [],
      showDialog: false,
      dialogWid: '0%',
      showPage: true,
      componentName: ''
    };
  },
  computed: {
    userId () {
      return getMemberId();
    },
    ...mapState({
      isVip: (state) => state.login.isVip
    }),
    showVipCom () {
      return this.isVip === '普通用户';
    },
  },
  mounted () {
    getMatchRecord({ userId: this.userId }).then(res => {
      this.data = res.data.data;
    });
  },
  methods: {
    ...mapMutations('simpleMatching', [ 'setComponentName', 'setCompanyName' ]),
    resetMatching (entityName) {
      this.setComponentName('Waiting');
      this.setCompanyName(entityName);
      this.$router.push({ path: '/simpleMatching/search', query: { isRecord: true } });
    },
    closeDialog () {
      this.detailParams = this.$options.data().detailParams;
      this.dialogVisible = false;
    },
    closeDia () {
      this.showDialog = false;
    },
    getDetails (item) {
      this.dialogVisible = true;
      this.detailParams.recordId = item.id;
      this.getData();
    },
    getData () {
      getMatchRecordResult({ ...this.detailParams }).then(res => {
        this.details = res.data.data.projectList;
        this.projectNum = res.data.data.totalSize;
      });
    },
    handleCurrentChange (cur) {
      if (this.showVipCom) {
        this.showDialog = true;
        this.showPage = false;
        setTimeout(() => {
          this.showPage = true;
        }, 1);
        this.componentName = 'vipPermission';
        return;
      }
      this.detailParams.pageNo = cur;
      this.getData();
    },
    goToDetail (child) {
      const routeData = this.$router.resolve({
        path: '/formaldetails/jiedu',
        query: {
          id: child.policyId,
          inputId: child.projectId
        }
      });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style scoped lang="less">
.main {
  margin-top: 26px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
    .header_left {
      font-size: 16px;
    }
    .header_right {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .content {
    padding: 18px 16px 16px 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px .5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 10px;
    .content_card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card_left {
        .left_title {
          font-size: 18px;
          font-weight: bold;
        }
        .left_time {
          display: flex;
          margin-top: 15px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.4);
          .time_icon {
            margin-left: 18px;
          }
        }
      }
      .card_right {
        display: flex;
        font-size: 14px;
        .right_btn {
          cursor: pointer;
          color: rgba(65, 96, 146, 0.6);
          margin-right: 30px;
        }
        .right_project {
          cursor: pointer;
          color: rgba(65, 96, 146, 0.6);
          margin-right: 28px;
          .project_num {
            color: rgba(48, 120, 240, 1);
          }
        }
        .right_icon {
          font-weight: bold;
        }
      }
    }
  }
}
.content_box {
  margin-top: 19px;
  padding: 30px 19px 0 19px;
  border-top: 2px solid rgba(187, 187, 187, 0.5);
  .box_title {
    color: rgba(97, 119, 154, 1);
    font-size: 14px;
    margin-bottom: 10px;
  }
  .box_table {
    font-size: 14px;
    table {
        border-collapse: collapse;
        margin: 0 auto;
        text-align: left;
    }
    table td, table th {
        border: 1px solid rgba(187, 187, 187, 0.5);
        color: #666;
        height: 30px;
        padding: 10px 15px;
    }
    table thead th {
      // background-color: #CCE8EB;
      width: 100px;
    }
  }
  .box_foot {
    cursor: pointer;
    color: rgba(65, 96, 146, 0.6);
    font-size: 14px;
    margin-top: 16px;
    text-align: center;
  }
}
</style>
